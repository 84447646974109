import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { PermissionsService } from '@services/permissions/permissions.service';

@Injectable()
export class DoorAccessGuardService {
    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
    ) {}

    public canActivate(): boolean | UrlTree {
        return (
            this.permissionsService.checkPermission({
                name: 'door_accesses',
            }) || this.router.parseUrl('/error/403')
        );
    }
}
