import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import posthog, { CaptureOptions, CaptureResult, Properties } from 'posthog-js';

@Injectable({
    providedIn: 'root',
})
export class PosthogService {
    constructor() {
        if (environment.production) {
            posthog.init(environment.posthog_api_key, {
                api_host: environment.posthog_api_host,
                capture_pageview: false,
                capture_pageleave: false,
                session_recording: {
                    maskAllInputs: true,
                    maskTextSelector: '*',
                },
                persistence: 'localStorage+cookie',
            });
        }
    }

    public identifyUser(user: any): void {
        if (environment.production) {
            posthog.identify(user._id, {
                email: user.email,
                name: `${user.first_name} ${user.last_name}`,
                roles: user.roles,
            });
        }
    }

    public resetUser(): void {
        if (environment.production) posthog.reset();
    }

    public captureEvent(
        event_name: string,
        properties?: Properties,
        options?: CaptureOptions,
    ): void | CaptureResult {
        if (environment.production) {
            posthog.capture(event_name, properties, options);
        }
    }
}
