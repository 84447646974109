import { RecommendationsGuard } from './services/auth/recommendations.guard';
import { ExpectedPaymentsAmountGuardService } from './services/auth/expected-payments-amount.guard';
import { OdbFopsGuardService } from './services/auth/odb-fops.guard';
import { TasksGuardService } from './services/auth/tasks.guard';
import { WithdrawalGuardService } from '@services/auth/withdrawal.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterviewsGuardService } from '@services/auth/interviews.guard';
import { LocalizationsGuardService } from '@services/auth/localizations.guard';
import { ProgrammeGuardService } from '@services/auth/programme.guard';
import { RefundsGuardService } from '@services/auth/refunds.guards';

import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuardService } from './services/auth/auth.guard';
import { BirthdayGuardService } from './services/auth/birthday.guard';
import { CalendarsConfirmGuardService } from './services/auth/calendars-confirm.guard';
import { CashboxGuardService } from './services/auth/cashbox.guard';
import { CertificatesGuardService } from './services/auth/certificates.guard';
import { ClassesGuardService } from './services/auth/classes.guard';
import { CoachesGuardService } from './services/auth/coaches.guard';
import { CompaniesGuardService } from './services/auth/companies.guard';
import { CouponsGuardService } from './services/auth/coupons.guard';
import { CoursesGuardService } from './services/auth/courses.guard';
import { CvsGuardService } from './services/auth/cvs.guard';
import { EditAccountsGuardService } from './services/auth/edit-account.guard';
import { GroupsChatsGuardService } from './services/auth/groups-chats.guard';
import { PaymentsGuardService } from '@services/auth/payments.guard';
import { FopsAddGuardService } from './services/auth/fops-add.guard';
import { BudgetGuardService } from './services/auth/groups-budget.guard';
import { HelpPageGuardService } from './services/auth/help-page.guard';
import { MailingGuardService } from './services/auth/mailing.guard';
import { MergeUsersGuardService } from './services/auth/merge-users.guard';
import { NewCoachGuardService } from './services/auth/new-caoch-profile.guard';
import { NewCoachCheckAndSchoolAccessGuardService } from './services/auth/new-coach-check-and-school-access.guard';
import { PollCreateGuardService } from './services/auth/poll-create.guard';
import { PollsGuardService } from './services/auth/polls.guard';
import { ReservesGuardService } from './services/auth/reserves.guard';
import { SearchGuardService } from './services/auth/search.guard';
import { StatisticsGuardService } from './services/auth/statistics.guard';
import { StudentAddGuardService } from './services/auth/student-add.guard';
import { SupportGuardService } from './services/auth/support.guard';
import { UserLoadCheckGuardService } from './services/auth/user-load-check.guard';
import { UsersListGuardService } from './services/auth/users-list.guard';
import { VideocourseGuardService } from './services/auth/videocourse.guard';
import { LmsUpdatesGuardService } from '@services/auth/lms-updates.guard';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';
import { CoachInstructionGuardService } from '@services/auth/coach-instruction.guard';
import { CodeTasksGuardService } from '@services/auth/code-tasks.guard';
import { SkillsGuardService } from '@services/auth/skills.guard';
import { MailConfigGuardService } from '@services/auth/mail-config.guard';
import { KeycallsGuardService } from '@services/auth/keycalls.guard';
import { VacanciesGuardService } from '@services/auth/vacancies.guard';
import { StoreGuard } from '@services/auth/store.guard';
import { SprintsGuardService } from '@services/auth/sprints.guard';
import { ParserGuardService } from '@services/auth/parser.guard';
import { QuizGuard } from '@services/auth/quiz.guard';
import { DebtorsGuard } from '@services/auth/detors.guard';
import { ConsultationsGuardService } from '@services/auth/consultations.guard';
import { PurchasesGuardService } from '@services/auth/purchases.guard';
import { CustomerBalancesGuard } from '@services/auth/customer-balances.guard';
import { PasswordResetLogsGuardService } from '@services/auth/password-reset-logs.guard';
import { SystemControlGuardService } from '@services/auth/system-control.guard';
import { LonglistGuard } from '@services/auth/longlist.guard';
import { DoorAccessGuardService } from '@services/auth/door-access.guard';

const routes: Routes = [
    {
        path: '',
        data: {
            i18nextNamespaces: ['page-tt-layout'],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/schedule',
            },
            {
                path: '',
                component: LayoutComponent,
                canActivate: [UserLoadCheckGuardService],
                children: [
                    {
                        path: 'cashbox',
                        loadChildren: (): any =>
                            import('./components/cashbox/cashbox.module').then(
                                (m): any => m.CashboxModule,
                            ),
                        canActivate: [CashboxGuardService],
                    },
                    {
                        path: 'cashbox-categories',
                        loadChildren: (): any =>
                            import(
                                './components/cashbox-categories/cashbox-categories.module'
                            ).then((m): any => m.CashboxCategoriesModule),
                        canActivate: [CashboxGuardService],
                    },
                    {
                        path: 'groups',
                        loadChildren: (): any =>
                            import('./components/groups/groups.module').then(
                                (m): any => m.GroupsModule,
                            ),
                    },
                    {
                        path: 'search',
                        loadChildren: (): any =>
                            import('./components/search/search.module').then(
                                (m): any => m.SearchModule,
                            ),
                        canActivate: [SearchGuardService],
                    },
                    {
                        path: 'videocourses',
                        loadChildren: (): any =>
                            import(
                                './components/video-courses/video-courses.module'
                            ).then((m): any => m.VideoCoursesModule),
                        canActivate: [VideocourseGuardService],
                    },
                    {
                        path: 'mailings',
                        loadChildren: (): any =>
                            import(
                                './components/mailings/mailings.module'
                            ).then((m): any => m.MailingsModule),
                        canActivate: [MailingGuardService],
                    },
                    {
                        path: 'questions',
                        loadChildren: (): any =>
                            import(
                                './components/polls/poll-questions/poll-questions.module'
                            ).then((m): any => m.PollQuestionsModule),
                        canActivate: [PollCreateGuardService],
                    },
                    {
                        path: 'purchases',
                        loadChildren: (): any =>
                            import(
                                './components/purchases/purchases.module'
                            ).then((m): any => m.PurchasesModule),
                        canActivate: [PurchasesGuardService],
                    },
                    {
                        path: 'fops',
                        loadChildren: (): any =>
                            import('./components/fops/fop-main.module').then(
                                (m): any => m.FopMainModule,
                            ),
                        canActivate: [FopsAddGuardService],
                    },
                    {
                        path: 'budget',
                        loadChildren: (): any =>
                            import('./components/budget/budget.module').then(
                                (m): any => m.BudgetModule,
                            ),
                        canActivate: [BudgetGuardService],
                    },
                    {
                        path: 'coupon',
                        loadChildren: (): any =>
                            import('./components/coupon/coupon.module').then(
                                (m): any => m.CouponModule,
                            ),
                        canActivate: [CouponsGuardService],
                    },
                    {
                        path: 'companies',
                        loadChildren: (): any =>
                            import(
                                './components/companies/companies.module'
                            ).then((m): any => m.CompaniesModule),
                        canActivate: [CompaniesGuardService],
                    },
                    {
                        path: 'support',
                        loadChildren: (): any =>
                            import('./components/support/support.module').then(
                                (m): any => m.SupportModule,
                            ),
                        canActivate: [SupportGuardService],
                    },
                    {
                        path: 'coaches',
                        children: [
                            {
                                path: 'new',
                                children: [
                                    {
                                        path: '',
                                        pathMatch: 'full',
                                        redirectTo: '/coaches',
                                    },
                                    {
                                        path: ':new_coach_id',
                                        canActivate: [
                                            NewCoachCheckAndSchoolAccessGuardService,
                                        ],
                                        children: [
                                            {
                                                path: '',
                                                pathMatch: 'full',
                                                loadChildren: (): any =>
                                                    import(
                                                        './components/coaches/coach-profile/coach-profile.module'
                                                    ).then(
                                                        (m): any =>
                                                            m.CoachProfileModule,
                                                    ),
                                                canActivate: [
                                                    NewCoachGuardService,
                                                ],
                                            },
                                            {
                                                path: 'edit',
                                                loadChildren: (): any =>
                                                    import(
                                                        './components/coaches/coach-add/coach-add.module'
                                                    ).then(
                                                        (m): any =>
                                                            m.CoachAddModule,
                                                    ),
                                                data: {
                                                    edit: true,
                                                },
                                                canActivate: [
                                                    EditAccountsGuardService,
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: ':user_id',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/information',
                            },
                            {
                                path: ':user_id/information',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/information',
                            },
                            {
                                path: ':user_id/groups',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/groups',
                            },
                            {
                                path: ':user_id/payment',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/payments',
                            },
                            {
                                path: ':user_id/params',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/params',
                            },
                            {
                                path: ':user_id/edit',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/edit',
                            },
                            {
                                path: '',
                                pathMatch: 'full',
                                loadChildren: (): any =>
                                    import(
                                        './components/coaches/coaches.module'
                                    ).then((m): any => m.CoachesModule),
                                canActivate: [CoachesGuardService],
                            },
                        ],
                    },
                    {
                        path: 'students',
                        children: [
                            {
                                path: ':user_id',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/information',
                            },
                            {
                                path: ':user_id/information',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/information',
                            },
                            {
                                path: ':user_id/groups',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/groups',
                            },
                            {
                                path: ':user_id/polls',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/polls',
                            },
                            {
                                path: ':user_id/payment',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/payments',
                            },
                            {
                                path: ':user_id/payments',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/payments',
                            },
                            {
                                path: ':user_id/cvs',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/cvs',
                            },
                            {
                                path: ':user_id/notes',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/notes',
                            },
                            {
                                path: ':user_id/params',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/params',
                            },
                            {
                                path: ':user_id/edit',
                                pathMatch: 'full',
                                redirectTo: '/accounts/:user_id/edit',
                            },
                        ],
                    },
                    {
                        path: 'profiles',
                        loadChildren: (): any =>
                            import('./components/profile/profile.module').then(
                                (m): any => m.ProfileModule,
                            ),
                    },
                    {
                        path: 'accounts',
                        loadChildren: (): any =>
                            import('./components/account/account.module').then(
                                (m): any => m.AccountModule,
                            ),
                    },
                    {
                        path: 'achievements',
                        loadChildren: (): any =>
                            import(
                                './components/achievements/achievements.module'
                            ).then((m): any => m.AchievementsModule),
                    },
                    {
                        path: 'confirms',
                        loadChildren: (): any =>
                            import(
                                './components/confirms/confirms.module'
                            ).then((m): any => m.ConfirmsModule),
                        canActivate: [CalendarsConfirmGuardService],
                    },
                    {
                        path: 'schools',
                        loadChildren: (): any =>
                            import('./components/schools/schools.module').then(
                                (m): any => m.SchoolsModule,
                            ),
                    },
                    {
                        path: 'courses',
                        loadChildren: (): any =>
                            import('./components/courses/courses.module').then(
                                (m): any => m.CoursesModule,
                            ),
                        canActivate: [CoursesGuardService],
                    },
                    {
                        path: 'statistics',
                        loadChildren: (): any =>
                            import(
                                './components/statistics/statistics.module'
                            ).then((m): any => m.StatisticsModule),
                        canActivate: [StatisticsGuardService],
                    },
                    {
                        path: 'invite',
                        loadChildren: (): any =>
                            import(
                                './components/invite-user/invite-user.module'
                            ).then((m): any => m.InviteUserModule),
                        canActivate: [StudentAddGuardService],
                    },
                    {
                        path: 'schedule',
                        loadChildren: (): any =>
                            import(
                                './components/schedule/schedule.module'
                            ).then((m): any => m.ScheduleModule),
                    },
                    {
                        path: 'users',
                        loadChildren: (): any =>
                            import('./components/users/users.module').then(
                                (m): any => m.UsersModule,
                            ),
                        canActivate: [UsersListGuardService],
                    },
                    {
                        path: 'app-history',
                        loadChildren: (): any =>
                            import(
                                './components/app-history/app-history.module'
                            ).then((m): any => m.AppHistoryModule),
                    },
                    {
                        path: 'polls',
                        loadChildren: (): any =>
                            import('./components/polls/polls.module').then(
                                (m): any => m.PollsModule,
                            ),
                        canActivate: [PollsGuardService],
                    },
                    {
                        path: 'classes',
                        loadChildren: (): any =>
                            import('./components/classes/classes.module').then(
                                (m): any => m.ClassesModule,
                            ),
                        canActivate: [ClassesGuardService],
                    },
                    {
                        path: 'cvs',
                        loadChildren: (): any =>
                            import('./components/cvs/cvs.module').then(
                                (m): any => m.CvsModule,
                            ),
                        canActivate: [CvsGuardService],
                    },
                    {
                        path: 'skills',
                        loadChildren: (): any =>
                            import('./components/skills/skills.module').then(
                                (m): any => m.SkillsModule,
                            ),
                        canActivate: [SkillsGuardService],
                    },
                    {
                        path: 'birthdays',
                        loadChildren: (): any =>
                            import(
                                './components/birthday/birthday.module'
                            ).then((m): any => m.BirthdayModule),
                        canActivate: [BirthdayGuardService],
                    },
                    {
                        path: 'interviews',
                        loadChildren: (): any =>
                            import(
                                './components/interviews/interviews.module'
                            ).then((m): any => m.InterviewsModule),
                        canActivate: [InterviewsGuardService],
                    },
                    {
                        path: 'payments',
                        loadChildren: (): any =>
                            import(
                                './components/payments/payments.module'
                            ).then((m): any => m.PaymentsModule),
                        canActivate: [PaymentsGuardService],
                    },
                    {
                        path: 'meetings-dashboard',
                        loadChildren: (): any =>
                            import(
                                './components/meetings-dashboard/meetings-dashboard.module'
                            ).then((m): any => m.MeetingsDashboardModule),
                    },
                    {
                        path: 'merge',
                        loadChildren: (): any =>
                            import(
                                './components/merge-users/merge-users.module'
                            ).then((m): any => m.MergeUsersModule),
                        canActivate: [MergeUsersGuardService],
                    },
                    {
                        path: 'videos',
                        loadChildren: (): any =>
                            import('./components/videos/videos.module').then(
                                (m): any => m.VideosModule,
                            ),
                    },
                    {
                        path: 'help',
                        loadChildren: (): any =>
                            import('./components/help/help.module').then(
                                (m): any => m.HelpModule,
                            ),
                        canActivate: [HelpPageGuardService],
                    },
                    {
                        path: 'certificates',
                        loadChildren: (): any =>
                            import(
                                './components/certificates/certificates.module'
                            ).then((m) => m.CertificatesModule),
                        canActivate: [CertificatesGuardService],
                    },
                    {
                        path: 'groups-chats',
                        loadChildren: (): any =>
                            import(
                                './components/groups-chats/groups-chats.module'
                            ).then((m) => m.GroupsChatsModule),
                        canActivate: [GroupsChatsGuardService],
                    },
                    {
                        path: 'programme',
                        loadChildren: (): any =>
                            import(
                                './components/programme/programme.module'
                            ).then((m) => m.ProgrammeModule),
                        canActivate: [ProgrammeGuardService],
                    },
                    {
                        path: 'code-tasks',
                        loadChildren: (): any =>
                            import(
                                './components/code-tasks/code-tasks.module'
                            ).then((m) => m.CodeTasksModule),
                        canActivate: [CodeTasksGuardService],
                    },
                    {
                        path: 'referrals',
                        loadChildren: (): any =>
                            import(
                                './components/referrals/referrals.module'
                            ).then((m) => m.ReferralsModule),
                    },
                    {
                        path: 'refund-statements',
                        loadChildren: (): any =>
                            import(
                                './components/refund-statements/refund-statements.module'
                            ).then((m) => m.RefundStatementsModule),
                        canActivate: [RefundsGuardService],
                    },
                    {
                        path: 'expected-payments-amount',
                        loadChildren: (): any =>
                            import(
                                './components/expected-payments-amount/expected-payments-amount.module'
                            ).then((m) => m.ExpectedPaymentsAmountModule),
                        canActivate: [ExpectedPaymentsAmountGuardService],
                    },
                    {
                        path: 'vpo',
                        loadChildren: (): any =>
                            import('./components/vpo/vpo.module').then(
                                (m) => m.VpoModule,
                            ),
                    },
                    {
                        path: 'debtors',
                        canActivate: [DebtorsGuard],
                        loadChildren: (): any =>
                            import('./components/debtors/debtors.module').then(
                                (m) => m.DebtorsModule,
                            ),
                    },
                    {
                        path: 'door-access',
                        loadChildren: (): any =>
                            import(
                                './components/door-access/door-access.module'
                            ).then((m): any => m.DoorAccessModule),
                        canActivate: [DoorAccessGuardService],
                    },
                    {
                        path: 'updates',
                        loadChildren: (): any =>
                            import('./components/updates/updates.module').then(
                                (m) => m.UpdatesModule,
                            ),
                        canActivate: [LmsUpdatesGuardService],
                    },
                    {
                        path: 'parser',
                        loadChildren: (): any =>
                            import('./components/parser/parser.module').then(
                                (m): any => m.ParserModule,
                            ),
                        canActivate: [ParserGuardService],
                    },
                    {
                        path: 'coach-instruction',
                        loadChildren: (): any =>
                            import(
                                './components/coach-instruction/coach-instruction.module'
                            ).then((m) => m.CoachInstructionModule),
                        canActivate: [CoachInstructionGuardService],
                    },
                    {
                        path: 'localizations',
                        loadChildren: (): any =>
                            import(
                                './components/localizations/localizations.module'
                            ).then((m): any => m.LocalizationsModule),
                        canActivate: [LocalizationsGuardService],
                    },
                    {
                        path: 'reserves',
                        loadChildren: (): any =>
                            import(
                                './components/reserves-page/reserves-page.module'
                            ).then((m) => m.ReservesPageModule),
                        canActivate: [ReservesGuardService],
                    },
                    {
                        path: 'withdrawals',
                        canActivate: [WithdrawalGuardService],
                        loadChildren: (): any =>
                            import(
                                './components/withdrawals/withdrawals.module'
                            ).then((m) => m.WithdrawalsModule),
                    },
                    {
                        path: 'tasks',
                        canActivate: [TasksGuardService],
                        loadChildren: (): any =>
                            import('./components/tasks/tasks.module').then(
                                (m) => m.TasksModule,
                            ),
                    },
                    {
                        path: 'mail-config',
                        canActivate: [MailConfigGuardService],
                        loadChildren: (): any =>
                            import(
                                './components/mail-config/mail-config.module'
                            ).then((m) => m.MailConfigModule),
                    },
                    {
                        path: 'odb-fops',
                        canActivate: [OdbFopsGuardService],
                        loadChildren: (): any =>
                            import(
                                './components/odb-fops/odb-fops.module'
                            ).then((m) => m.OdbFopsModule),
                    },
                    {
                        path: 'keycalls',
                        canActivate: [KeycallsGuardService],
                        loadChildren: (): any =>
                            import(
                                './components/keycalls/keycalls.module'
                            ).then((m) => m.KeycallsModule),
                    },
                    {
                        path: 'vacancies',
                        loadChildren: (): any =>
                            import(
                                './components/vacancies/vacancies.module'
                            ).then((m) => m.VacanciesModule),
                        canActivate: [VacanciesGuardService],
                    },
                    {
                        path: 'recommendations',
                        canActivate: [RecommendationsGuard],
                        loadChildren: (): any =>
                            import(
                                './components/recommendations/recommendations.module'
                            ).then((m) => m.RecommendationsModule),
                    },
                    {
                        path: 'store',
                        canActivate: [StoreGuard],
                        loadChildren: () =>
                            import(
                                './components/store-courses/store-courses.module'
                            ).then((m) => m.StoreCoursesModule),
                    },
                    {
                        path: 'sprints',
                        canActivate: [SprintsGuardService],
                        loadChildren: () =>
                            import('./components/sprints/sprints.module').then(
                                (m) => m.SprintsModule,
                            ),
                    },
                    {
                        path: 'freeze-student',
                        data: {
                            status: 'freeze',
                        },
                        loadChildren: () =>
                            import(
                                './components/other-student-list/other-student-list.module'
                            ).then((m) => m.OtherStudentListModule),
                    },
                    {
                        path: 'limited-student',
                        data: {
                            status: 'limited',
                        },
                        loadChildren: () =>
                            import(
                                './components/other-student-list/other-student-list.module'
                            ).then((m) => m.OtherStudentListModule),
                    },
                    {
                        path: 'students-employment',
                        loadChildren: (): any =>
                            import(
                                './components/students-checklist/students-checklist.module'
                            ).then((m): any => m.StudentsChecklistModule),
                    },
                    {
                        path: 'checklist',
                        loadChildren: (): any =>
                            import(
                                './components/checklist/checklist.module'
                            ).then((m): any => m.ChecklistModule),
                    },
                    {
                        path: 'quiz',
                        canActivate: [QuizGuard],
                        loadChildren: (): any =>
                            import('./components/quiz/quiz.module').then(
                                (m): any => m.QuizModule,
                            ),
                    },
                    {
                        path: 'workbooks',
                        loadChildren: () =>
                            import(
                                './components/workbooks/workbooks.module'
                            ).then((m) => m.WorkbooksModule),
                    },
                    {
                        path: 'customer-balances',
                        canActivate: [CustomerBalancesGuard],
                        loadChildren: () =>
                            import(
                                './components/customer-balances/customer-balances.module'
                            ).then((m) => m.CustomerBalancesModule),
                    },
                    {
                        path: 'password-reset-logs',
                        canActivate: [PasswordResetLogsGuardService],
                        loadChildren: () =>
                            import(
                                './components/password-reset-logs/password-reset-logs.module'
                            ).then((m) => m.PasswordResetLogsModule),
                    },
                    {
                        path: 'consultations-list',
                        loadChildren: (): any =>
                            import(
                                './components/consultations/consultations.module'
                            ).then((m): any => m.ConsultationsModule),
                        canActivate: [ConsultationsGuardService],
                    },
                    {
                        path: 'consultations',
                        loadChildren: (): any =>
                            import(
                                './components/consultations/consultations.module'
                            ).then((m): any => m.ConsultationsModule),
                        canActivate: [ConsultationsGuardService],
                    },
                    {
                        path: 'longlist',
                        canActivate: [LonglistGuard],
                        loadChildren: () =>
                            import(
                                './components/longlist/longlist.module'
                            ).then((m) => m.LonglistModule),
                    },
                    {
                        path: 'system-control',
                        loadChildren: (): any =>
                            import(
                                './components/system-control/system-control.module'
                            ).then((m): any => m.SystemControlModule),
                        canActivate: [SystemControlGuardService],
                    },
                    {
                        path: 'material-ui',
                        loadComponent: () =>
                            import(
                                './components/material-ui/material-ui.component'
                            ).then((mod) => mod.MaterialUiComponent),
                    },
                ],
            },
            {
                path: 'error/:code',
                loadChildren: (): any =>
                    import('./components/error/error.module').then(
                        (m): any => m.ErrorModule,
                    ),
            },
        ],
    },
    {
        path: 'login',
        loadChildren: (): any =>
            import('./components/access/access.module').then(
                (m): any => m.AccessModule,
            ),
    },
    {
        path: 'confirm',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/login',
            },
            {
                path: ':token',
                pathMatch: 'full',
                redirectTo: '/login/confirm/:token',
            },
        ],
    },
    // Редиректы
    {
        path: 'schools',
        pathMatch: 'full',
        redirectTo: '/schedule',
    },
    {
        path: 'schools/:school_id',
        pathMatch: 'full',
        redirectTo: '/schedule',
    },
    {
        path: 'schools/:school_id/groups',
        pathMatch: 'full',
        redirectTo: '/groups',
    },
    {
        path: 'schools/:school_id/groups/:group_id',
        pathMatch: 'full',
        redirectTo: '/groups/:group_id/main',
    },
    {
        path: 'schools/:school_id/groups/:group_id/detail',
        pathMatch: 'full',
        redirectTo: '/groups/:group_id/main',
    },
    {
        path: 'schools/:school_id/groups/:group_id/students',
        pathMatch: 'full',
        redirectTo: '/groups/:group_id/students',
    },
    {
        path: 'schools/:school_id/groups/:group_id/find',
        pathMatch: 'full',
        redirectTo: '/groups/:group_id/add/student',
    },
    {
        path: 'schools/:school_id/coaches',
        pathMatch: 'full',
        redirectTo: '/coaches',
    },
    {
        path: 'schools/:school_id/coaches/add',
        pathMatch: 'full',
        redirectTo: '/coaches/add',
    },
    {
        path: 'schools/:school_id/coaches/add',
        pathMatch: 'full',
        redirectTo: '/coaches/add',
    },
    {
        path: 'schools/:school_id/coaches/new_coach',
        pathMatch: 'full',
        redirectTo: '/coaches',
    },
    {
        path: 'schools/:school_id/coaches/new_coach/:new_coach_id',
        pathMatch: 'full',
        redirectTo: '/coaches/new/:new_coach_id',
    },
    {
        path: 'schools/:school_id/coaches/new_coach/:new_coach_id/edit',
        pathMatch: 'full',
        redirectTo: '/coaches/new/:new_coach_id/edit',
    },
    {
        path: 'schools/:school_id/coaches/:coach_id',
        pathMatch: 'full',
        redirectTo: '/coaches/:coach_id/information',
    },
    {
        path: 'schools/:school_id/coaches/:coach_id/edit',
        pathMatch: 'full',
        redirectTo: '/coaches/:coach_id/edit',
    },
    {
        path: 'video/:school_id',
        pathMatch: 'full',
        redirectTo: '/videos/:school_id/cameras',
    },
    {
        path: 'video/:school_id/videos',
        pathMatch: 'full',
        redirectTo: '/videos/:school_id/list',
    },
    {
        path: '**',
        redirectTo: '/error/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
